var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"worship-visit-life"},[_c('div',{staticClass:"life-content"},[_vm._l((_vm.deads),function(dead,index){return _c('div',{key:dead.id,staticClass:"item"},[_c('div',{staticClass:"item-list"},[_vm._m(0,true),_c('div',{staticClass:"dead-txt"},[_vm._v(_vm._s(dead.gender_desc))])]),_c('div',{staticClass:"item-list"},[_vm._m(1,true),_c('div',{staticClass:"dead-txt"},[_vm._v(_vm._s(dead.nation))])]),_c('div',{staticClass:"item-list"},[_vm._m(2,true),_c('div',{staticClass:"dead-txt"},[_vm._v(_vm._s(dead.native_place))])]),_c('div',{staticClass:"item-list"},[_vm._m(3,true),_c('div',{staticClass:"dead-txt"},[_vm._v(_vm._s(dead.profession))])]),_c('div',{staticClass:"item-list"},[_vm._m(4,true),_c('div',{staticClass:"dead-txt"},[_vm._v(_vm._s(dead.faith))])]),_c('div',{staticClass:"item-list"},[_vm._m(5,true),_c('div',{staticClass:"dead-txt"},[_vm._v(_vm._s(dead.birthday))])]),_c('div',{staticClass:"item-list"},[_vm._m(6,true),_c('div',{staticClass:"dead-txt"},[_vm._v(_vm._s(dead.dead_day))])]),_c('div',{staticClass:"item-list"},[_vm._m(7,true),_c('div',{staticClass:"dead-txt"},[_vm._v(_vm._s(dead.birth_place))])]),_c('div',{staticClass:"item-list"},[_vm._m(8,true),_c('div',{staticClass:"dead-txt"},[_vm._v(_vm._s(dead.bury_place))])]),_c('div',{staticClass:"item-list"},[_c('label',{},[_vm._v("个 人 简 介")]),_c('div',{staticClass:"dead-txt"},[_vm._v(_vm._s(dead.summary))])]),_c('br')])}),_c('button',{staticClass:"life-btn",staticStyle:{"position":"absolute","top":"40px","right":"20px"},attrs:{"type":"button"}},[_vm._v("完善资料")]),_c('button',{staticClass:"life-btn",staticStyle:{"position":"absolute","top":"120px","right":"20px"},attrs:{"type":"button"}},[_vm._v("增加逝者")])],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{},[_vm._v("性"),_c('span',{staticClass:"occupied-2"}),_vm._v("别")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{},[_vm._v("民"),_c('span',{staticClass:"occupied-2"}),_vm._v("族")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{},[_vm._v("籍"),_c('span',{staticClass:"occupied-2"}),_vm._v("贯")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{},[_vm._v("职"),_c('span',{staticClass:"occupied-2"}),_vm._v("业")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{},[_vm._v("信"),_c('span',{staticClass:"occupied-2"}),_vm._v("仰")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{},[_vm._v("生"),_c('span',{staticClass:"occupied-2"}),_vm._v("辰")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{},[_vm._v("忌"),_c('span',{staticClass:"occupied-2"}),_vm._v("日")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{},[_vm._v("出"),_c('span',{staticClass:"occupied-1"}),_vm._v("生"),_c('span',{staticClass:"occupied-1"}),_vm._v("地")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{},[_vm._v("安"),_c('span',{staticClass:"occupied-1"}),_vm._v("葬"),_c('span',{staticClass:"occupied-1"}),_vm._v("地")])
}]

export { render, staticRenderFns }