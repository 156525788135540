<template>
  <div class="worship-visit-life">
    <div class="life-content">
      <div class="item"  v-for="(dead, index) in deads" :key="dead.id">
        <div class="item-list">
          <label class="">性<span class="occupied-2"></span>别</label>
          <div class="dead-txt">{{dead.gender_desc}}</div>
        </div>
        <div class="item-list">
          <label class="">民<span class="occupied-2"></span>族</label>
          <div class="dead-txt">{{dead.nation}}</div>
        </div>
        <div class="item-list">
          <label class="">籍<span class="occupied-2"></span>贯</label>
          <div class="dead-txt">{{dead.native_place}}</div>
        </div>
        <div class="item-list">
          <label class="">职<span class="occupied-2"></span>业</label>
          <div class="dead-txt">{{dead.profession}}</div>
        </div>
        <div class="item-list">
          <label class="">信<span class="occupied-2"></span>仰</label>
          <div class="dead-txt">{{dead.faith}}</div>
        </div>
        <div class="item-list">
          <label class="">生<span class="occupied-2"></span>辰</label>
          <div class="dead-txt">{{ dead.birthday }}</div>
        </div>
        <div class="item-list">
          <label class="">忌<span class="occupied-2"></span>日</label>
          <div class="dead-txt">{{ dead.dead_day}}</div>
        </div>
        <div class="item-list">
          <label class="">出<span class="occupied-1"></span>生<span class="occupied-1"></span>地</label>
          <div class="dead-txt">{{dead.birth_place}}</div>
        </div>
        <div class="item-list">
          <label class="">安<span class="occupied-1"></span>葬<span class="occupied-1"></span>地</label>
          <div class="dead-txt">{{dead.bury_place}}</div>
        </div>
        <div class="item-list">
          <label class="">个&nbsp;人&nbsp;简&nbsp;介</label>
          <div class="dead-txt">{{dead.summary}}</div>
        </div>
        <br>
      </div>
      <button class="life-btn" style="position: absolute;top: 40px;right: 20px" type="button">完善资料</button>
      <button class="life-btn" style="position: absolute;top: 120px;right: 20px" type="button">增加逝者</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'worship-visit-life',
    data() {
      return {
        hallId: '48e32dc432204326a86f8ce00285dab2',
        deads:[]
      }
    },
    created() {
      this.getDeadByHall()
    },
    methods: {
      getHallDataStatusDesc(status) {
        let desc = '图片审核中';
        if (status == 3 || status == 5) {
          desc = '图片审核不通过';
        }
        return desc;
      },
      getDeadByHall: function () {
        let _this = this;
        let param = {
          hallId: _this.hallId
        };
        _this.$api.hall.getDeadByHall(param)
          .then(res => {
            if(res.data){
              res.data.forEach((data, i) =>{
                if (data.birthday) {
                  data.birthday = data.birthday.replace('-', '.').replace('-', '.')
                    .replace('年', '.').replace('月', '.')
                  if (data.birthday.length >= 10) {
                    data.birthday = data.birthday.substr(0, 10);
                  }
                }
                if (data.dead_day) {
                  data.dead_day = data.dead_day.replace('-', '.').replace('-', '.')
                    .replace('年', '.').replace('月', '.')
                  if (data.dead_day.length >= 10) {
                    data.dead_day = data.dead_day.substr(0, 10);
                  }
                }

                if (!data.status || data.status != 4) {
                  data.checkImg = true;
                  data.checkTitle = _this.getHallDataStatusDesc(data.status);
                } else {
                  data.checkImg = false;
                  data.checkTitle = "";
                }

                _this.deads.push(data);
              })
            }
          });
      }
    }
  }
</script>

<style lang="scss" scoped>
.worship-visit-life {
  position: relative;
  z-index: 22;
  min-height: 400px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 0 20px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
  .life-content {
    width: 694px;
    margin: 0 auto;
    .life-btn {
      width: 150px;
      height: 50px;
      background: linear-gradient(-90deg, #7AAADA 0%, #407EB2 100%);
      box-shadow: -2px 3px 5px 1px rgba(101, 101, 100, 0.5);
      border-radius: 25px;
      border: none;
      font-size: 18px;
      font-family: SimSun;
      font-weight: bold;
      color: #FFFFFF;
    }
    .item {
      padding: 36px 15px;

      .item-list {
        font-size: 18px;
        color: #444444;
        margin-top: 10px;
        @include w-flex;
        @include align-items;

        .occupied-1 {width: 15px}
        .occupied-2 {width: 44px}
        .dead-txt {flex: 1;margin-left: 50px}

        label {
          @include w-flex;
          margin: 0;
          width: 102px;
        }
      }
    }
  }
  &::-webkit-scrollbar {
   display: none; /* Chrome Safari */
  }
}
</style>
